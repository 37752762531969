#site-footer {
    background-color: $black;
    padding: 39px 0;
    text-align: center;
    .f-top {
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(#ffffff, 0.1);
        > a {
            img {
                width: 227px;
            }
        }
        nav {
            padding-top: 5px;
            li {
                margin: 16px 0;
                a {
                    font-size: 16px;
                    color: $gray;
                }
            }
        }
    }
    .f-bottom {
        padding-top: 37px;
        span {
            color: rgba(#ffffff, 0.43);
            font-size: 12px;
        }
        nav {
            li {
                margin: 0 18px;
                a {
                    color: rgba(#ffffff, 0.3);
                    font-size: 12px;
                }
            }
        }
    }
}

.reach-out {
    h3 {
        font: 24px "PT Serif", serif;
    }
    .info {
        > {
            address,
            a {
                font: 16px/22px "PT Sans", sans-serif;
                color: $white;
                display: block;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    //Only Desktop
    #site-footer {
        .f-top {
            overflow: hidden;
            > a {
                float: left;
                img {
                    width: 170px;
                }
            }
            nav {
                float: right;
                li {
                    margin: 0 18px;
                    float: left;
                    a {
                    }
                }
            }
        }
        .f-bottom {
            overflow: hidden;
            padding-top: 12px;
            span {
                float: left;
            }
            nav {
                float: right;
                li {
                    a {
                    }
                }
            }
        }
    }

    .reach-out {
        .info {
            text-align: right;
        }
    }
}
