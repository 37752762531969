.icon {
    background-repeat: no-repeat;
    display: inline-block;
}

.icon-nav {
    height: 20px;
    width: 20px;
    background-size: cover;
    display: inline-block;
    margin-bottom: -5px;
    margin-right: 7px;
    margin-left: 7px;

    &.icon-prev {
        transform: rotate(180deg);
    }

    &.icon-big {
        height: 35px;
        width: 35px;
        margin-bottom: -11px;
    }
}

.icon-facebook {
    width: 21px;
    height: 20px;
    background-size: 21px 20px;
    background-image: url("../images/icon-facebook.svg");
}

.icon-twitter {
    width: 26px;
    height: 21px;
    background-size: 26px 21px;
    background-image: url("../images/icon-twitter.svg");
}

.icon-instagram {
    width: 21px;
    height: 21px;
    background-size: 21px 21px;
    background-image: url("../images/icon-instagram.svg");
}

.icon-linkedin {
    width: 21px;
    height: 21px;
    background-size: 21px 21px;
    background-image: url("../images/icon-linkedin.svg");
}

.close {
    opacity: 1;
    z-index: 1000;
    .icon-close {
        position: absolute;
        right: 32px;
        top: 20px;
        width: 16px;
        height: 16px;

        &:before,
        &:after {
            position: absolute;
            left: 15px;
            content: " ";
            height: 20px;
            width: 3px;
            background-color: #484b4c;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}

.icon-arrow {
    width: 10px;
    height: 14px;
    border-left: none;
    border-bottom: none;
    border-right: 2px solid $primary;
    border-top: 2px solid $primary;
    transform: rotate(45deg);
    background-color: transparent;
    &.icon-arrow-light {
        border-color: $white;
    }
    &.icon-arrow-left {
        transform: rotate(-135deg);
    }
    &.disabled {
        border-color: $light-gray !important;
    }
}
