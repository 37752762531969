.content {
    min-height: 300px;
    padding-top: 28px;
    padding-bottom: 30px;

    article {
        header {
            time {
                font: 12px "PT Sans", sans-serif;
                font-weight: bold;
                color: $dark-gray;
                letter-spacing: 6px;
            }
            h1 {
                font: 12px "PT Serif", serif;
                font-size: 36px;
                color: $primary;
            }
        }
        p {
            font: 20px "PT Serif", serif;
            color: $dark-gray;
            line-height: 26px;
        }
        .bordered p {
            &:first-child {
                padding-top: 24px;
                margin-top: 24px;
                border-top: 1px solid $light-gray;
            }
        }

        .article-video {
            img {
            }
        }
    }
}

.content-social {
    padding: 14px 0;
    border-top: #dddee2 solid 1px;
    .social-nav {
        text-align: center;
        display: block;
    }
}

@include media-breakpoint-up(xl) {
    .content {
        padding-top: 67px;
        article {
            header {
                h2 {
                }
                a {
                    float: right;
                }
            }
        }
    }

    .content-social {
        .social-nav {
            margin-top: -15px;
            display: inline-block;
            float: right;
        }
    }
}
