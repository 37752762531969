button,
input {
    outline: none !important;
}

label {
    margin-bottom: 0;
}

.btn {
    color: $white !important;
    text-transform: uppercase;
    font-family: "Pt Sans", sans-serif;
    letter-spacing: 1.87px;
    font-size: 14px;
    line-height: 40px;
    padding: 6px 0;

    &.btn-outline-secondary {
        color: $primary !important;
        border-color: $primary;
        background-color: transparent;

        &:hover {
            color: $secondary !important;
            border-color: $secondary;
            background-color: transparent;
        }
    }
}
/* Custom Checbox */
.checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    color: #797d7b;
    letter-spacing: 0;
    line-height: 18px;
    font: 13px "Pt Sans", sans-serif;

    a {
        color: $black !important;
        text-decoration: underline;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
            background-color: $success;
            border-color: $success;
        }
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    &:hover {
        input ~ .checkmark {
            background-color: #ccc;
        }
    }

    .checkmark:after {
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #f4f4f5;
    border: 2px solid #979797;
    border-radius: 4px;

    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}

.with-shadow {
    box-shadow: 0 2px 10px 0 rgba(72, 75, 76, 0.25);
}

.select {
    position: relative;
    line-height: 35px;
    border-radius: 0.25em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 1px solid #dddee2;
    background-image: none;
    padding: 0 30px 0 12px;
    color: $dark-gray;
    height: 38px;
    cursor: pointer;
    background-size: 8px 12px;
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    font: 16px "PT Serif", Serif;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    width: 100%;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}

.inputfile + label {
    color: $light-gray;
}

.inputfile + label {
    border: 1px solid $light-gray;
    padding: 0;
    border-radius: 4px;
}

.inputfile + label span,
.inputfile + label strong {
    padding: 6px 10px;
}

.inputfile + label span {
    width: 90%;
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
    font-family: "PT Serif", serif;
    font-size: 16px;
    color: $dark-gray;
}

.inputfile + label strong {
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-top: 10px;
    padding: 6px;
    background-image: url("../images/icon-upload.svg");
}

@include media-breakpoint-up(xl) {
    //Only Desktop
    .contact-form-container {
        height: auto;
        clear: both;
        padding-top: 55px;
        .container {
            width: 730px;
            .contact-form-left {
                width: 50%;
                float: left;
            }
            .contact-form {
                width: 50%;
                float: left;
            }
        }
    }
}
