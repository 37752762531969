.wall-nav {
    h2:not(.q-title) {
        font: 48px "PT Serif", serif;
        color: $dark-gray;
        line-height: 28px;
    }
    > ul {
        margin-left: -15px;
        margin-right: -15px;
        .brick {
            margin-bottom: 33px;
            display: inline-block;
            width: 100%;
            img {
                border-radius: 8px;
                margin-bottom: 9px;
            }
            h2 {
                font-size: 24px;
                a {
                    color: $dark-gray;
                }
            }
            .upperlink {
                font: 12px "PT Sans", sans-serif;
                font-weight: bold;
                color: $gray;
            }
            time {
                font: 12px "PT Sans", sans-serif;
                color: $gray;
            }
            p {
                font: 16px "PT Sans", sans-serif;
                color: $gray;
                line-height: 22px;
            }
            &:hover {
                border-bottom: 1px solid $primary;
            }
            .navigation {
                border-bottom: 1px solid $light-gray;
                padding-bottom: 13px;
            }
        }
    }
}

.ref-nav {
    ul {
        li {
            text-align: center;
            a {
                font: 13px "PT Serif", serif;
                font-weight: 700;
                font-style: italic;
                color: $dark-gray;
                img {
                    padding-bottom: 9px;
                }
            }
        }
    }
}

.people-nav {
    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
            width: 100%;
            float: left;
            text-align: center;
            padding: 32px 15px;
        }
    }
}

.people {
    padding: 15px;
    text-align: center;
    font-family: "PT Sans", sans-serif;
    display: block;
    img {
        border-radius: 3px;
        margin-bottom: 8px;
    }
    .title {
        font: 700 13px "PT Sans", sans-serif;
        color: $dark-gray;
        letter-spacing: 3.3px;
        text-transform: uppercase;
        margin-bottom: 8px;
    }
    .name {
        font: 700 24px "PT Serif", serif;
        color: $dark-gray;
        letter-spacing: 0;
        margin-bottom: 0;
    }
}

.list-nav {
    column-count: 1;
    text-align: left;
    text-transform: uppercase;
    font: 20px "PT Serif", serif;
    line-height: 46px;
}

.pn-nav {
    font: 15px "PT Sans", sans-serif;
    font-weight: bold;
    color: #455758;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase;
    overflow: hidden;
    border-bottom: 1px solid $light-gray;
}

.social-nav-dark {
    .icon {
        float: left;
        filter: invert(53%) sepia(4%) saturate(258%) hue-rotate(98deg) brightness(92%) contrast(92%);
    }
}

.social-nav {
    .icon {
        float: left;
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(205deg) brightness(103%) contrast(103%);
    }
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    > .disabled > a,
    > .disabled > a:focus,
    > .disabled > a:hover,
    > .disabled > span,
    > .disabled > span:focus,
    > .disabled > span:hover {
        cursor: not-allowed;
    }
    > li {
        display: inline;
        > a,
        > span {
            width: 52px;
            height: 52px;
            position: relative;
            float: left;
            text-decoration: none;
            color: #797d7b;
            background-color: #dddee2;
            border-color: #dddee2;
            border-radius: 4px;
            margin: 3px;
            font-size: 14px;
            letter-spacing: 1.87px;
            text-align: center;
            line-height: 54px;
        }
        &.active {
            > a,
            > span {
                color: $white;
                background-color: #234259;
                border-color: #234259;
            }
        }
    }
}

@include media-breakpoint-between(md, xl) {
    //Only Tablet
    .wall-nav {
        > ul {
            //columns: 2;
        }
    }

    .list-nav {
        text-align: center;

        ol {
            li {
                list-style-position: inside;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    //Only Desktop
    .wall-nav {
        > ul {
            .brick {
                //width: 33%;
                float: left;
            }
        }
        &.three-item {
            > ul {
                li {
                    //float: left;
                }
            }
        }
    }

    .list-nav {
        column-count: 2;
        text-align: left;
        ol {
            padding-left: 0;
        }
    }

    .ref-nav {
        ul {
            margin: 0 128px;
        }
    }

    .people-nav {
        ul {
            li {
                width: 25%;
            }
        }
    }

    .pn-nav {
        li {
            &:first-child {
                float: left;
            }
            &:last-child {
                float: right;
            }
        }
    }
}
