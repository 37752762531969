.hp-hero {
    height: 100vh;
    min-height: 300px;
    background: no-repeat center center scroll;
    background-size: cover;
    width: 100%;
    position: relative;
    .hp-hero-image {
        height: 100vh;
        min-height: 300px;
        background: no-repeat center center scroll;
        background-size: cover;
        width: 100%;
        z-index: 8;
        position: absolute;
        display: none;
        &.active {
            z-index: 10;
            display: block;
        }
        .last-active {
            z-index: 9;
        }
    }

    .hero-summary {
        display: table;
        position: absolute;
        width: 100%;
        height: auto;
        top: 40vh;
        right: 0;
        width: auto;
        z-index: 20;
        text-align: center;
        p {
            text-align: left;
        }
    }
}

@include media-breakpoint-up(md) {
    //Not Mobile
    .hp-hero {
        .hero-summary {
            width: 686px;
            text-align: left;
            top: 40vh;
        }
    }
}

@include media-breakpoint-up(xl) {
    //Only Desktop
    .hp-hero {
        float: left;
        padding-right: 0;
        flex-direction: column;
        display: block;
        .hp-hero-left,
        .hp-hero-right {
            display: inline-block;
            margin-bottom: 0;
        }
        .hp-hero-left {
            width: 51%;
            margin-top: 140px;

            .hp-hero-buttons {
                width: 50%;
            }
        }
        .hp-hero-right {
            width: 49%;
        }
    }

    .homepage {
        #app {
            margin-top: 0;
        }
        #container-nav {
            position: absolute;
            width: 390px;
            top: inherit;
        }

        #main-nav {
            display: flex;
            padding-top: 7px;
            .navbar-brand {
                background-image: url("../images/logo.svg");
                background-size: 279px 48px;
                width: 279px;
                height: 48px;
                z-index: 2000;
                margin: 28px auto;
            }

            .navbar-toggler {
                border: none;
                z-index: 2100;
                position: relative;
                height: 22px;
                display: inline-block;
                padding: 7px 0 0 10px;
            }

            &.navbar-expand-lg {
                justify-content: space-between;
            }
        }
        #mainnav {
            position: absolute;
            flex-grow: inherit;
            left: 0;
            transition: all 0.15s ease-in-out;
            background-color: $primary;
            width: 390px;
            top: 0;
            text-align: center;
            height: 100vh;
            padding: 135px 15px 0;
            .menu {
                width: 100%;
                li {
                    width: 100%;
                    a {
                        font: 15px "Pt Sans", sans-serif;
                        line-height: 55px;
                        text-align: center;
                        font-weight: bold;
                        color: $white;
                        text-transform: uppercase;
                    }
                }
            }
            .bottom-nav {
                display: block;
                bottom: 25px;
                .social-nav {
                    display: inline-block;
                    margin-bottom: 76px;
                    li {
                        margin-right: 8px;
                        &:last-child {
                            margin-right: 0;
                        }
                        a {
                            display: block;
                        }
                    }
                }
            }
        }
        .pipe-nav {
            ul {
                li {
                    font-family: "PT Sans", sans-serif;
                    font-size: 20px;
                    margin-right: 20px;
                    &::after {
                        content: "|";
                        position: absolute;
                        right: -14px;
                        color: $white;
                        font-size: 18px;
                        line-height: 36px;
                    }
                    &:last-child {
                        &::after {
                            content: "";
                        }
                    }
                    a {
                        color: $white;
                        &.selected {
                            color: $secondary;
                        }
                    }
                }
            }
        }
    }
}
