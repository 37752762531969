.search-list {
}

.searchbox-container {
    display: block;
    position: relative;
    height: 72px;
    .searchbox {
        width: 100%;
        position: absolute;
        caret-color: $primary;
        border: 1px solid $primary;
        font-size: 16px;
        color: $primary;
        letter-spacing: 0;
        line-height: 40px;
        padding-left: 24px;
        height: 72px;
        caret-color: $secondary;
    }
    p {
        font-size: 24px;
        position: absolute;
        left: 24px;
        top: -2px;
        color: $primary;
        margin-top: 20px;
        pointer-events: none;
        &::after {
            content: "";
            width: 1px;
            height: 30px;
            position: absolute;
            right: -12px;
            top: 3px;
            background-color: $secondary;
            animation: 1s blink step-end infinite;
        }
    }
    svg {
        position: absolute;
        right: 24px;
        top: 24px;
    }
}

.inline-search {
    position: relative;
    margin-bottom: 32px;
}

@include media-breakpoint-up(xl) {
    .searchbox-container {
        .searchbox {
            font-size: 24px;
            & + p {
                font-size: 24px;
            }
        }
    }
}

@keyframes blink {
    from,
    to {
        background-color: transparent;
    }
    50% {
        background-color: $secondary;
    }
}
