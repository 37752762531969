.logobar {
    height: 176px;
    background-color: $secondary;
    padding-top: 39px;
    text-align: center;
}

#peopleModal {
    .people-card {
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
        img {
            width: 255px;
            height: 255px;
            object-fit: cover;
            text-align: center;
            display: block;
            margin: 0 auto 15px auto;
        }
        .name {
            text-transform: uppercase;
        }
    }
    .people-body {
        h6 {
            font: 13px "PT Sans", sans-serif;
            font-weight: bold;
            color: $dark-gray;
            margin-bottom: 0;
        }
        p {
            font-size: 13px;
            color: $dark-gray;
        }

        .people-info {
            a {
                color: $dark-gray;
                font-size: 13px;
            }
        }
    }

    .modal-header {
        border-bottom: none;
    }

    .modal-body {
        padding-top: 0;
        padding-bottom: 0;
    }

    .modal-footer {
        border-top: none;
        display: block;
        padding: 27px 15px;

        .social-nav-dark {
            display: block;
            text-align: center;
            opacity: 0.3;
        }
    }
}
.modal-content {
    border-radius: 8px;
    border: none;
    overflow: hidden;
}

.map-pin {
    height: 80px;
    width: 204px;
    background-image: url("../images/pin.png");
    background-size: 204px 80px;
    background-position: center center;
    filter: drop-shadow(0px 3px 23px rgba(0, 0, 0, 0.5));
    background-repeat: no-repeat;
}
@include media-breakpoint-up(xl) {
    //Only Desktop
    .modal-dialog {
        max-width: 729px;
    }

    #peopleModal {
        .staff-card {
        }
        .staff-body {
            margin: 0 94px;
        }
        .modal-footer {
            padding: 27px 94px;
            .social-nav {
                float: right;
            }
        }
    }
}
