@import "~glider-js/glider";
// @import "~slick-carousel/slick/slick";
//@import "~slick-carousel/slick/slick-theme";

.h-slider {
    > div {
        a {
            padding: 15px;
            text-align: center;
            font-family: "PT Sans", sans-serif;
            display: block;
            img {
                border-radius: 3px;
                margin-bottom: 8px;
            }
            .title {
                font: 700 13px "PT Sans", sans-serif;
                color: $dark-gray;
                letter-spacing: 3.3px;
                text-transform: uppercase;
                margin-bottom: 8px;
            }
            .name {
                font: 700 24px "PT Serif", serif;
                color: $dark-gray;
                letter-spacing: 0;
                margin-bottom: 0;
            }
        }
    }
    &.h-slider-light {
        a {
            text-align: center;
            .service {
                font: 700 20px "PT Serif", serif;
                color: $white;
                letter-spacing: 0;
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }
    }
}

.h-slider-buttons {
    text-align: right;
    padding-right: 25px;
    padding-bottom: 15px;
}

@include media-breakpoint-between(md, xl) {
}
@include media-breakpoint-up(xl) {
    .h-slider-buttons {
        display: block;
        position: absolute;
        right: calc(50vw - 555px);
        top: -35px;
        &.light {
        }
    }

    .h-slider {
        > div {
            &:first-child {
                a {
                    padding-left: 0;
                }
            }
        }
    }
}
