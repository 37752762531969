// Fonts
@import "fonts";
// Variables
@import "variables";
// Bootstrap
@import "bootstrap";
// Mixins
@import "mixins";
// Functions
@import "functions";
//Forms
@import "forms";
// Helpers
@import "helpers";
//Includes
@import "homepage";
@import "header";
@import "footer";
@import "search";
@import "icons";
@import "lists";
@import "content";
@import "widgets";
@import "contact";

//Libraries
@import "slick";
@import "~izitoast/dist/css/iziToast";

//Generic Customizations
#app {
    margin-top: 102px;
    right: 0;
    transition: right 0.25s ease-out;
    position: relative;
    top: 0;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.container-right {
    padding-left: 0;
    padding-right: 0;
    clear: both;
}
a {
    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
        outline-style: none;
        color: $primary;
    }
}

.n-margin {
    position: relative;
    top: -37px;
}
.upperlink {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.bg-gray {
    background-color: #f4f4f4;
}

figure {
    margin: 32px 0 22px;
    picture {
    }
    figcaption {
        text-align: center;
        margin-top: 6px;
        font-size: 12px;
        color: #878584;
        letter-spacing: 0;
        line-height: 20px;
    }
}
@include media-breakpoint-between(md, xl) {
}
@include media-breakpoint-up(xl) {
    //Only Desktop
    #app {
        margin-top: 72px;
    }

    .container-right {
        padding-right: 0;
        padding-left: calc(50vw - 555px);
    }
}
