#container-nav {
    position: fixed;
    z-index: 1000;
    top: 0;
}

#main-nav {
    //white-space: nowrap;
    display: flex;
    padding-top: 7px;
    height: 102px;
    .navbar-brand {
        background-image: url("../images/logo.svg");
        background-size: 181px 31px;
        width: 181px;
        height: 31px;
    }

    .navbar-toggler {
        border: none;
        z-index: 2100;
        position: relative;
        height: 22px;
        display: inline-block;
        padding: 7px 0 0 10px;
    }

    &.navbar-expand-lg {
        justify-content: space-between;
    }
}
#mainnav {
    display: block;
    position: fixed;
    flex-grow: inherit;
    left: 100%;
    transition: all 0.15s ease-in-out;
    background-color: $primary;
    width: 100%;
    top: 102px;
    overflow-y: scroll;
    text-align: center;
    z-index: 1500;
    height: 100%;
    overflow-x: hidden;
    padding: 45px 15px 0;
    .menu {
        width: 100%;
        li {
            width: 100%;
            a {
                font: 15px "Pt Sans", sans-serif;
                line-height: 75px;
                text-align: left;
                font-weight: normal;
                color: $white;
                text-transform: uppercase;
            }
        }
    }
    .bottom-nav {
        .social-nav {
            display: inline-block;
            margin-bottom: 76px;
            h6 {
                font: 15px "Pt Sans", sans-serif;
                font-weight: bold;
            }
            li {
                margin-right: 8px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    display: block;
                }
            }
        }
    }
}

#navcheck {
    position: fixed;
    opacity: 0;
}

#icon-navcheck {
    position: absolute;
    right: 0;
    top: 22px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    display: inline-block;
    width: 48px;
    height: 28px;
    cursor: pointer;
    &::before {
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        opacity: 0;
        pointer-events: none;
        transition: opacity, background-color 0.5s $timing-function;
    }
    .burger {
        position: absolute;
        top: 1em;
        right: 1em;
        z-index: 2200;
        width: 26px;
        height: 26px;
        margin: 0;
        padding: 0;
        transition: opacity 0.5s $timing-function;
        &::before,
        .bar,
        &::after {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 3px;
            background: $white;
            border-radius: 3px;
            content: "";
            transition: all 0.5s $timing-function;
        }
        .bar {
            top: 44%;
        }
        &::before {
            top: 0;
            transform-origin: top left;
        }
        &::after {
            bottom: 0;
            transform-origin: bottom left;
        }
    }
}

[id="navcheck"]:checked {
    & ~ nav#container-nav {
        #mainnav {
            left: 0;
        }
        #icon-navcheck {
            &::before {
                opacity: 1;
                pointer-events: auto;
                //background-color: $white;
                transition: all 0.5s $timing-function;
            }
            .burger {
                &::before,
                &::after {
                    width: 141.42% !important; // Pythagore!
                    background-color: $secondary !important;
                }
                &::before {
                    transform: rotate(45deg) translateY(-50%);
                }
                &::after {
                    transform: rotate(-45deg) translateY(50%);
                }
                .bar {
                    transform: scale(0.1);
                }
            }
        }
    }
}

.pipe-nav {
    text-transform: uppercase;
    a {
        font: 12px "Pt Sans", sans-serif;
    }
}

.header-image-container {
    position: relative;
    .header-image {
        height: 360px;
        color: $white;
        padding-top: 140px;
        background-size: cover !important;
        h1,
        h2 {
            font: 42px "PT Serif", serif;
            color: $white;
            letter-spacing: 0;
            line-height: 44px;
        }
    }
    .quote {
        position: relative;
        right: 0;
        margin-bottom: 0;
        width: 100%;
        padding-right: 30px;
        max-width: 1000px;
    }
}

.footer-map {
    height: 595px;
    color: $white;
    background-size: cover !important;
    .footer-map-navigation {
        height: 595px;
        width: auto;
        margin: 0 auto;
        position: relative;
        text-align: center;
        .map-pin {
            top: 50%;
            transform: translateY(-50%);
            display: block;
            margin: 0 auto;
            position: absolute;
            left: -50%;
            right: -50%;
        }
        a {
            width: 330px;
            bottom: 30px;
            display: block;
            margin: 0 auto;
            position: absolute;
            left: -50%;
            right: -50%;
        }
    }
}

.bottom-nav {
    position: absolute;
    left: -50%;
    right: -50%;
    bottom: 120px;
    background-color: $primary;
}

@include media-breakpoint-up(md) {
    //Not Mobile
    #main-nav {
        .navbar-brand {
            background-size: 195px 34px;
            width: 195px;
            height: 34px;
        }
    }

    .header-image-container {
        .quote {
            position: absolute;
            bottom: -90px;
            right: 0;
            margin-bottom: 0;
            width: 85%;
            max-width: inherit;
        }
    }
}

@include media-breakpoint-between(md, xl) {
    .header-image-container {
        .header-image {
            height: 295px;
            padding-top: 70px;
            margin-bottom: 100px;
            h1 {
                font-size: 50px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    //Only Desktop
    #main-nav {
        justify-content: space-between;
        height: 100%;
        .navbar-brand {
            margin: 0;
        }

        .navbar-toggler {
            display: none;
        }

        .navbar-nav {
            li {
                .nav-link {
                    font-size: 18px;
                    padding-left: 15px;
                }
            }
            &:last-child {
                a {
                    padding-right: 0;
                }
            }
        }
    }

    #mainnav {
        position: relative;
        left: auto;
        background-color: transparent;
        transition: none;
        padding: 5px 0;
        overflow: hidden;
        top: 0;
        .menu {
            padding-top: 0;
            li {
                float: left;
                margin-bottom: 0;
                text-align: left;
                display: table;
                width: 90px;
                a {
                    font-size: 12px;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: 8px 0;
                    line-height: 14px;
                    display: table-cell;
                    vertical-align: middle;
                    height: 47px;
                }
            }
        }
        .bottom-nav {
            display: none;
        }
    }

    #icon-navcheck {
        display: none;
    }
    .header-image-container {
        .header-image {
            height: 295px;
            color: $white;
            padding-top: 90px;
            margin-bottom: 100px;
            h1,
            h2 {
                font-size: 56px;
                line-height: 1;
            }
        }
        .quote {
            position: absolute;
            bottom: -90px;
            right: 0;
            margin-bottom: 0;
            width: 75%;
            padding-right: calc(50vw - 555px);
        }
    }
}
